/**
 * User Microservice
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserApplicationModel { 
    userApplicationId?: number;
    applicationSettingId?: number;
    applicationLabel?: string | null;
    attributesValue?: string | null;
    applicationId?: number | null;
    organizationApplicationId?: string | null;
    userId?: number;
    userAttributes?: string | null;
    isActive?: boolean;
    applicationName?: string | null;
    assignedAs?: string | null;
    groupName?: string | null;
    logoUrl?: string | null;
    isSelect?: boolean;
    createdBy?: string | null;
    createdDate?: string;
    modifiedBy?: string | null;
    modifiedDate?: string;
}

