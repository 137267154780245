import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationMethod, AuthenticationTypeModel, AuthenticationTypeService, UserCheckEnrollCodeModel, UserCredentialService } from 'src/app/services/identity';
import { UsersService } from 'src/app/services/user';
import { AuthService } from '../auth.service';
import { OrganizationSettingService } from 'src/app/services/organization';
import { AuthenticationMethodSettings, ConfigurationSettingsEnum, ConstantService, ThemeEnum } from 'src/app/services/common/constant.service';
import { ValidationService } from 'src/app/services/common/validation.service';
import { Observable, Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedAuthenticationService } from '../shared/shared-authentication.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { SoftTokenOutput, SofttokenInputModel } from '@securenvoy/se-shared';


@Component({
  selector: 'app-enforce-push-enrollment',
  templateUrl: './enforce-push-enrollment.component.html',
  styleUrls: ['./enforce-push-enrollment.component.scss']
})
export class EnforcePushEnrollmentComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription;

  orgId: number;
  orgMnemonic: string;
  username = '';
  logoImage: string;

  isSaving = false;
  buttonDisabled = true;
  pushEnabled: boolean = true;
  pushEnforced: boolean = false;
  pushAllowed: boolean = true;
  softTokenType: string = 'securenvoy';

  hasAutoEnrolled: boolean = false;
  checkCode: string;
  
  isSecondFactor: string;

  settingsLoaded: boolean;

  authenticationType: AuthenticationTypeModel = {
    authenticationTypeId: 0,
    authenticationTypeName: AuthenticationMethod.Push,
    userName: '',
    organizationMnemonic: '',
    sequence: 0,
    jsonData: null,
    isActive: true,
    isDefault: true,
    userCheckEnrollCodeModel: {
      enrollUrl: '',
      checkCode: ''
    },
    pushEnabled: true
  };
  

  @ViewChild('acc') accordion: NgbAccordion;

  options!: SofttokenInputModel;

  constructor(
    private userService: UsersService,
    private authService: AuthService,
    private authTypeService: AuthenticationTypeService,
    public constantService: ConstantService,
    private organizationSettingService: OrganizationSettingService,
    public validationService: ValidationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userCredentialService: UserCredentialService,
    private sharedService: SharedAuthenticationService,
    private toastrService: ToastrService
  ) { }


  ngOnInit() {
    const logoImage = this.constantService.getSessionStorage(ThemeEnum.mainLogo);
    this.logoImage = logoImage ?? "assets/images/se-white-logo.png";
    this.authenticationType.sequence = +this.constantService.decode(this.activatedRoute.snapshot.params.type);
    this.isSecondFactor = this.activatedRoute.snapshot.params.isSecondFactor;

    this.username = this.authService.userName;
    this.orgId = this.authService.organizationId;
    this.orgMnemonic = this.constantService.getSubdomain(false);

    this.pushEnabled = true;
        

    this.getAuthEnrollmentSettings();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }


  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  removeOverlay() {
    document.getElementById('overlay').style.display = 'none';
  }

  save() {
    this.isSaving = true;

    if (this.hasAutoEnrolled === true) {
      this.authenticationType.userCheckEnrollCodeModel = null;
      this.removeOverlay();
    } else {
      this.authenticationType.userCheckEnrollCodeModel.checkCode = this.checkCode;
    }
    this.authenticationType.pushEnabled = this.pushEnabled;
    this.saveToApi(this.authenticationType);
    this.isSaving = false;
  }

  getAuthEnrollmentSettings() {
    this.subscriptions.add(this.organizationSettingService.apiOrganizationSettingGetEnrollSettingsGet(0, this.constantService.AuthEnrollment, this.constantService.MultiFAEnrollment).subscribe(
      data => {
        let secondFASetting = JSON.parse(data[0].value);
        if (Array.isArray(secondFASetting) && secondFASetting.findIndex(x => x === AuthenticationMethodSettings.PushEnforce) < 0) {
          this.pushEnforced = true;
        }
        this.getMfaSettings();
      }
    ));
  }

  getMfaSettings() {
    this.subscriptions.add(this.organizationSettingService.apiOrganizationSettingGetSettingsOrganizationIdGet(0, ConfigurationSettingsEnum.MFA)
      .subscribe((data) => {
        this.softTokenType = data.find(x => x.key == 'SoftTokenAuthenticatorType')?.value ?? 'securenvoy';
        this.pushAllowed = !JSON.parse(data.find(x => x.key == 'DisableSoftTokenPush')?.value ?? 'false');
        this.options = {
          state: {
            pushAllowed: this.pushAllowed,
            pushEnabled: this.pushEnabled,
            pushEnforced: this.pushEnforced,
            authenticatorType: this.softTokenType
          },
          functions: {
            userEnrolReadyStatus: this.userEnrolReadyStatus.bind(this),
            userSeedUrl: this.userSeedUrl.bind(this)
          },
          validators: {
            regAddSpaceCharacterInputThirdParty: this.validationService.regAddSpaceCharacterInputThirdParty,
            regAddSpaceCharacterInput: this.validationService.regAddSpaceCharacterInput,
            regAddSpaceCharacterOutput: this.validationService.regAddSpaceCharacterOutput
          }
        }
        this.settingsLoaded = true;
    }))
  }

  saveToApi(model) {

    model.userName = this.username;
    this.subscriptions.add(this.authTypeService.apiAuthenticationTypeSaveOrUpdateUserAuthenticationTypePost(model).subscribe({
      next: data => {
        if (data === true) {
          this.toastrService.success('User authentication is updated', 'Success');
          const userData: any = {};
          userData.username = this.username;
          userData.authEnforcement = null;
          this.subscriptions.add(this.userCredentialService.apiUserCredentialUpdateUserAuthEnforcementStatusPut(this.orgMnemonic, userData).subscribe(
            async () => {
              sessionStorage.setItem('dataconf', '');

              if (!this.authService.$enforceData._value) {
                await this.sharedService.getUserDetail();
              }
              this.authService.$enforceData._value.authEnforcement = null;
              this.redirectNext();
            }));
        } else {
          this.toastrService.error('Push notification is not configured', 'Error', this.constantService.ToastError);
        }
      },
      error: () => {
        this.toastrService.error('Push notification is not configured', 'Error', this.constantService.ToastError);
      }
    }));
  }

  handleOutput(output: SoftTokenOutput) {
    this.authenticationType.userCheckEnrollCodeModel.enrollUrl = output.enrolUrl;
    this.hasAutoEnrolled = output.hasAutoEnrolled;
    this.checkCode = output.checkCode;
    this.pushEnabled = output.pushEnabled;

    if (this.hasAutoEnrolled || this.checkCode) {
      this.buttonDisabled = false;
    } else {
      this.buttonDisabled = true;
    }
  }

  redirectNext() {
    if (this.authService.$enforceData._value.isEnforcedSecondFactor && this.authenticationType.sequence === 1) {
      return this.router.navigate(['enforce-enrollment']);
    }
    this.authService.redirectUser();
  }

  logout() {
    this.authService.signOut();
  }

  userEnrolReadyStatus(isSoftTokenOnly: boolean): Observable<any> {
    return this.authTypeService.apiAuthenticationTypeUserEnrollReadyStatusGet(isSoftTokenOnly);
  }

  userSeedUrl(isSoftTokenOnly: boolean, isGenericToken: boolean): Observable<any> {
    return this.authTypeService.apiAuthenticationTypeGetUserSeedUrlGet(isSoftTokenOnly, isGenericToken);
  }

}
