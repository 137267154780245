/**
 * Identity Server Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AuthenticationMethodRadius } from './authenticationMethodRadius';
import { RadiusAuthentication } from './radiusAuthentication';


export interface RadiusCredential { 
    type?: AuthenticationMethodRadius;
    userInput?: string | null;
    radiusAuthentication?: RadiusAuthentication;
}

