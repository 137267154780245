import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { MyAppGroupService } from './api/myAppGroup.service';
import { MyAppGroupApplicationService } from './api/myAppGroupApplication.service';
import { PasswordService } from './api/password.service';
import { SyncUserService } from './api/syncUser.service';
import { TenantCleanupService } from './api/tenantCleanup.service';
import { UserAliasesService } from './api/userAliases.service';
import { UserApplicationService } from './api/userApplication.service';
import { UserCustomAttributeService } from './api/userCustomAttribute.service';
import { UserDirectoryService } from './api/userDirectory.service';
import { UserGroupService } from './api/userGroup.service';
import { UsersService } from './api/users.service';
import { VersionService } from './api/version.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
