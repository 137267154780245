import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AccountService } from './api/account.service';
import { AuthenticationTypeService } from './api/authenticationType.service';
import { CertificateService } from './api/certificate.service';
import { CredentialResetService } from './api/credentialReset.service';
import { HardwareTokenService } from './api/hardwareToken.service';
import { LegacySecurEnvoyAppService } from './api/legacySecurEnvoyApp.service';
import { LoginHistoryService } from './api/loginHistory.service';
import { QuestionSecretAnswerService } from './api/questionSecretAnswer.service';
import { RedirectService } from './api/redirect.service';
import { SettingsService } from './api/settings.service';
import { TenantCleanupService } from './api/tenantCleanup.service';
import { UserAuthCredentialService } from './api/userAuthCredential.service';
import { UserCredentialService } from './api/userCredential.service';
import { UserSessionService } from './api/userSession.service';
import { UserTokenService } from './api/userToken.service';
import { VersionService } from './api/version.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
