import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { ConfigurationSettingsEnum, ConstantService, ThemeEnum } from '../../services/common/constant.service';
import { AuthenticationMethod, AuthenticationTypeModel, AuthenticationTypeService, UserCredentialService } from '../../services/identity';
import { OrganizationSettingService } from '../../services/organization';
import { AuthService } from '../auth.service';
import { SharedAuthenticationService } from '../shared/shared-authentication.service';
import { UsersService } from '../../services/user';
import { ValidationService } from '../../services/common/validation.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { FidoInputModel, FidoTokenComponent } from '@securenvoy/se-shared';

@Component({
  selector: 'app-enforce-fido-enrollment',
  templateUrl: './enforce-fido-enrollment.component.html',
  styleUrls: ['./enforce-fido-enrollment.component.scss']
})
export class EnforceFidoEnrollmentComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription;

  logoImage: string;

  username: string;
  orgId: number;
  orgName: string;

  isSaving: boolean;

  mainForm: FormGroup;

  authenticationType: AuthenticationTypeModel = {
    authenticationTypeId: 0,
    authenticationTypeName: AuthenticationMethod.FidoToken,
    userName: '',
    organizationMnemonic: '',
    sequence: 0,
    jsonData: null,
    isActive: true,
    isDefault: true
  };

  fidoAllowMultiple: boolean;
  fidoAllowUsernameless: boolean;

  @ViewChild(FidoTokenComponent) fidoTokenComponent: FidoTokenComponent;

  options!: FidoInputModel;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private constantService: ConstantService,
    private fb: FormBuilder,
    private sharedService: SharedAuthenticationService,
    private toastrService: ToastrService,
    private authTypeService: AuthenticationTypeService,
    private userCredentialService: UserCredentialService,
    private router: Router,
    private organizationSettingService: OrganizationSettingService,
    private userService: UsersService,
    private validationService: ValidationService,
    private translateService: TranslateService,
    private ngbModal: NgbModal
  ) { }


  ngOnInit(): void {
    const logoImage = this.constantService.getSessionStorage(ThemeEnum.mainLogo);
    this.logoImage = logoImage ?? "assets/images/se-white-logo.png";
    this.username = this.authService.userName;
    this.orgId = this.authService.organizationId;
    this.orgName = this.constantService.getSubdomain(false);
    this.authenticationType.sequence = this.activatedRoute.snapshot.params.sequence;

    this.mainForm = this.fb.group({
      fidoCredential: [null, Validators.required],
      fidoBackupMethod: [null, Validators.required],
      fidoBackupData: [null, Validators.required]
    });

    this.getMFASettings(this.orgId);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getMFASettings(orgId) {
    this.subscriptions.add(this.organizationSettingService.apiOrganizationSettingGetSettingsOrganizationIdGet(orgId, ConfigurationSettingsEnum.MFA).subscribe(data => {
      this.fidoAllowMultiple = JSON.parse(data.find(x => x.key == 'FidoAllowMultiple')?.value ?? 'false');
      this.fidoAllowUsernameless = JSON.parse(data.find(x => x.key == 'FidoAllowResidentKey')?.value ?? 'false');
      if (this.authenticationType.sequence != 1) {
        this.fidoAllowUsernameless = false;        
      }
      this.options = {
        state: {
          isForLoggedInUser: true,
          allowMultipleTokens: this.fidoAllowMultiple,
          allowResidentKey: this.fidoAllowUsernameless
        },
        org: {
          id: this.orgId,
          name: this.orgName
        },
        user: {
          username: this.username
        },
        functions: {
          getCredentialOptions: this.getCredentialOptions.bind(this),
          identifyToken: this.identifyToken.bind(this),
          getBasicUserInfo: this.getBasicUserInfo.bind(this)
        },
        validators: {
          smsValidator: this.validationService.regPhoneNumber,
          emailValidator: this.validationService.regEmail,
        },
        modals: {
          identifyToken: this.identifyFidoModal.bind(this),
          removeToken: this.removeFidoModal.bind(this)
        }
      }
    }));
    
  }

  setCredential(output: any): void {
    this.mainForm.controls.fidoCredential.setValue(output.attestation);
    this.mainForm.controls.fidoBackupMethod.setValue(output.backupMethod);
    this.mainForm.controls.fidoBackupData.setValue(output.backupData);
  }

  save() {
    this.isSaving = true;
    this.authenticationType.userName = this.username;
    const creds = {
      attestations: this.mainForm.value.fidoCredential,
      backup: {
        method: this.mainForm.value.fidoBackupMethod,
        recipient: this.mainForm.value.fidoBackupData
      }
    }
    this.authenticationType.jsonData = JSON.stringify(creds);
    this.subscriptions.add(this.authTypeService.apiAuthenticationTypeSaveOrUpdateUserAuthenticationTypePost(this.authenticationType).subscribe({
      next: data => {
        if (data === true) {
          this.toastrService.success('User authentication is updated', 'Success');
          const userData: any = {};
          userData.username = this.username;
          userData.authEnforcement = null;
          this.subscriptions.add(this.userCredentialService.apiUserCredentialUpdateUserAuthEnforcementStatusPut(this.orgName, userData).subscribe(
            async () => {
              sessionStorage.setItem('dataconf', '');

              if (!this.authService.$enforceData._value) {
                await this.sharedService.getUserDetail();
              }
              this.authService.$enforceData._value.authEnforcement = null;
              this.redirectNext();
            }));
        } else {
          this.toastrService.error('Error', 'Error', this.constantService.ToastError);
        }
      },
      error: () => {
        this.toastrService.error('Error', 'Error', this.constantService.ToastError);
      }
    }));
    this.isSaving = false;
  }

  redirectNext() {
    if (this.authService.$enforceData._value.isEnforcedSecondFactor && this.authenticationType.sequence === 1) {
      return this.router.navigate(['enforce-enrollment']);
    }
    this.authService.redirectUser();
  }

  logout() {
    this.authService.signOut();
  }

  getBasicUserInfo(username: string, orgId: number): Observable<any> {
    return this.userService.apiUsersGetUserBasicInfoForUserUserNameOrganizationIdGet(username, orgId);
  }

  getCredentialOptions(orgName: string, username: string, isUsernameless: boolean): Observable<any> {
    return this.authTypeService.apiAuthenticationTypeFidoCredentialOptionsOrganizationMnemonicUserNameGet(orgName, username, isUsernameless);
  }

  identifyToken(orgName: string, username: string, credentialId: string): Observable<any> {
    return this.authTypeService.apiAuthenticationTypeIdentifyTokenOrganizationMnemonicUserNamePost(orgName, username, credentialId);
  }

  identifyFidoModal(): NgbModalRef {
    const identifyFidoModal = this.ngbModal.open(ConfirmDialogComponent, {
      size: 'md',
      windowClass: 'fadeInUp animated huge',
      backdrop: 'static',
      keyboard: false,
    });
    identifyFidoModal.componentInstance.message = this.translateService.instant('MyProfile.IdentifyTokenMessage');
    return identifyFidoModal;
  }

  removeFidoModal(): NgbModalRef {
    const deleteFidoModal = this.ngbModal.open(ConfirmDialogComponent, {
      size: 'md',
      windowClass: 'fadeInUp animated huge',
      backdrop: 'static',
      keyboard: false,
    });
    deleteFidoModal.componentInstance.message = this.translateService.instant('MyProfile.ConfirmDeleteTokenMessage');
    return deleteFidoModal;
  }

}
