import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { EnrollmentInputModel } from '@securenvoy/se-shared';
import { Observable } from 'rxjs';
import { AuthService } from '../../../authentication/auth.service';
import { ConstantService, UserRoles } from '../../../services/common/constant.service';
import { HasPermissionService } from '../../../services/common/has-permission.service';
import { ValidationService } from '../../../services/common/validation.service';
import { AuthenticationTypeService, HardwareTokenService, UserCredentialService } from '../../../services/identity';
import { OrganizationSettingService } from '../../../services/organization';
import { UsersService } from '../../../services/user';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-enrollment-container',
  templateUrl: './enrollment-container.component.html',
  styleUrls: ['./enrollment-container.component.scss']
})
export class EnrollmentContainerComponent implements OnInit {

  @Input() disablePrimaryFactor: boolean;
  @Input() hidePrimaryFactor: boolean;
  @Input() skipPasswordPresence: boolean;
  @Input() isUserActive: boolean;
  @Input() userId: number;
  @Input() username: string;

  @Output() savedEnrollmentEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  orgId: number;
  orgMnemonic: string;


  options: EnrollmentInputModel;


  constructor(
    private authService: AuthService,
    private organizationSettingService: OrganizationSettingService,
    private authTypeService: AuthenticationTypeService,
    private userService: UsersService,
    private userCredentialService: UserCredentialService,
    private hardwareTokenService: HardwareTokenService,
    private constantService: ConstantService,
    private validationService: ValidationService,
    private activatedRoute: ActivatedRoute,
    private permissions: HasPermissionService,
    private ngbModal: NgbModal,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {

    this.orgId = this.authService.organizationId;
    this.orgMnemonic = this.constantService.getSubdomain();

    let userType: string = 'user';
    if (this.activatedRoute.snapshot.params['userType']) {
      userType = this.constantService.decode(this.activatedRoute.snapshot.params['userType'])
    }
    let role = <UserRoles>userType;
    if (!role) {
      role = UserRoles.User;
    }



    this.options = {
      state: {
        disablePrimaryFactor: this.disablePrimaryFactor,
        hidePrimaryfactor: this.hidePrimaryFactor,
        skipPasswordPresence: this.skipPasswordPresence
      },
      org: {
        id: this.orgId,
        mnemonic: this.orgMnemonic
      },
      user: {
        id: this.userId,
        username: this.username,
        type: userType,
        isActive: this.isUserActive
      },
      currentUser: {
        username: this.authService.userName,
        type: this.authService.userType,
        hasPermissionForTarget: this.permissions.allowAccessForTarget(role)
      },
      functions: {
        getBasicUserInfo: this.getBasicUserInfo.bind(this),
        getOrgSettings: this.getOrgSettings.bind(this),
        getEnrolSettings: this.getEnrolSettings.bind(this),
        getHardwareTokens: this.getHardwareTokens.bind(this),
        getUserAuthStatus: this.getUserAuthStatus.bind(this),
        updateUserAuthStatus: this.updateUserAuthStatus.bind(this),
        doesUserPasswordExist: this.doesUserPasswordExist.bind(this),
        deactivateSecondFactor: this.deactivateSecondFactor.bind(this),
        getAllAuthTypes: this.getAllAuthTypes.bind(this),
        getAuthTypesForUser: this.getAuthTypesForUser.bind(this),
        updateThisUserAuthType: this.updateThisUserAuthType.bind(this),
        updateOtherUserAuthType: this.updateOtherUserAuthType.bind(this),
        fido: {
          getCredentialOptions: this.getCredentialOptions.bind(this),
          identifyToken: this.identifyToken.bind(this),
          getBasicUserInfo: this.getBasicUserInfo.bind(this)
        },
        softtoken: {
          userEnrolReadyStatus: this.userEnrolReadyStatus.bind(this),
          userSeedUrl: this.userSeedUrl.bind(this)
        }
      },
      validators: {
        regOnlyNumber: this.validationService.regOnlyNumber,
        regPhoneNumber: this.validationService.regPhoneNumber,
        regEmail: this.validationService.regEmail,
        softtoken: {
          regAddSpaceCharacterInputThirdParty: this.validationService.regAddSpaceCharacterInputThirdParty,
          regAddSpaceCharacterInput: this.validationService.regAddSpaceCharacterInput,
          regAddSpaceCharacterOutput: this.validationService.regAddSpaceCharacterOutput
        }
      },
      modals: {
        fido: {
          identifyToken: this.identifyFidoModal.bind(this),
          removeToken: this.removeFidoModal.bind(this)
        }
      }
    }
  }

  savedEnrollment() {
    this.savedEnrollmentEvent.emit();
  }


  getBasicUserInfo(username: string, orgId: number): Observable<any> {
    return this.userService.apiUsersGetUserBasicInfoForUserUserNameOrganizationIdGet(username, orgId);
  }

  getOrgSettings(organizationId: number, groupKey: string): Observable<any> {
    console.log(this);
    return this.organizationSettingService.apiOrganizationSettingGetSettingsOrganizationIdGet(organizationId, groupKey);
  }



  getEnrolSettings(orgId: number, groupKey: string, key: string, component?: string, userId?: number): Observable<any> {
    return this.organizationSettingService.apiOrganizationSettingGetEnrollSettingsOrganizationIdGet(orgId, groupKey, key, component, userId);
  }

  getHardwareTokens(orgId: number, username: string): Observable<any> {
    return this.hardwareTokenService.apiHardwareTokenGetByUsernameAndUnAssignedOrganizationIdGet(orgId, username);
  }

  getUserAuthStatus(mnemonic: string, username: string): Observable<any> {
    return this.userCredentialService.apiUserCredentialGetUserAuthEnforcementStatusOrganizationMnemonicUsernameGet(mnemonic, username);
  }

  updateUserAuthStatus(mnemonic: string, data: any): Observable<any> {
    return this.userCredentialService.apiUserCredentialUpdateUserAuthEnforcementStatusOrganizationMnemonicPut(mnemonic, data);
  }

  doesUserPasswordExist(userId: number): Observable<any> {
    return this.userCredentialService.apiUserCredentialIsUserPasswordExistsUserIdGet(userId);
  }

  deactivateSecondFactor(username: string, mnemonic: string): Observable<any> {
    return this.authTypeService.apiAuthenticationTypeInactiveUser2ndFactorUserNameOrganizationMnemonicGet(username, mnemonic);
  }

  getAllAuthTypes(): Observable<any> {
    return this.authTypeService.apiAuthenticationTypeGetallUserAuthenticationTypeGet();
  }

  getAuthTypesForUser(username: string, mnemonic: string): Observable<any> {
    return this.authTypeService.apiAuthenticationTypeGetallUserAuthenticationTypeUserNameOrganizationMnemonicGet(username, mnemonic);
  }

  updateThisUserAuthType(data: any): Observable<any> {
    return this.authTypeService.apiAuthenticationTypeSaveOrUpdateUserAuthenticationTypePost(data);
  }

  updateOtherUserAuthType(mnemonic: string, data: any): Observable<any> {
    return this.authTypeService.apiAuthenticationTypeSaveOrUpdateUserAuthenticationTypeForUserOrganizationMnemonicPost(mnemonic, data);
  }

  getCredentialOptions(orgName: string, username: string, isUsernameless: boolean): Observable<any> {
    return this.authTypeService.apiAuthenticationTypeFidoCredentialOptionsOrganizationMnemonicUserNameGet(orgName, username, isUsernameless);
  }

  identifyToken(orgName: string, username: string, credentialId: string): Observable<any> {
    return this.authTypeService.apiAuthenticationTypeIdentifyTokenOrganizationMnemonicUserNamePost(orgName, username, credentialId);
  }

  userEnrolReadyStatus(isSoftTokenOnly: boolean): Observable<any> {
    return this.authTypeService.apiAuthenticationTypeUserEnrollReadyStatusGet(isSoftTokenOnly);
  }

  userSeedUrl(isSoftTokenOnly: boolean, isGenericToken: boolean): Observable<any> {
    return this.authTypeService.apiAuthenticationTypeGetUserSeedUrlGet(isSoftTokenOnly, isGenericToken);
  }

  identifyFidoModal(): NgbModalRef {
    const identifyFidoModal = this.ngbModal.open(ConfirmDialogComponent, {
      size: 'md',
      windowClass: 'fadeInUp animated huge',
      backdrop: 'static',
      keyboard: false,
    });
    identifyFidoModal.componentInstance.message = this.translateService.instant('MyProfile.IdentifyTokenMessage');
    return identifyFidoModal;
  }

  removeFidoModal(): NgbModalRef {
    const deleteFidoModal = this.ngbModal.open(ConfirmDialogComponent, {
      size: 'md',
      windowClass: 'fadeInUp animated huge',
      backdrop: 'static',
      keyboard: false,
    });
    deleteFidoModal.componentInstance.message = this.translateService.instant('MyProfile.ConfirmDeleteTokenMessage');
    return deleteFidoModal;
  }

}
