export * from './myAppGroup.service';
import { MyAppGroupService } from './myAppGroup.service';
export * from './myAppGroupApplication.service';
import { MyAppGroupApplicationService } from './myAppGroupApplication.service';
export * from './password.service';
import { PasswordService } from './password.service';
export * from './syncUser.service';
import { SyncUserService } from './syncUser.service';
export * from './tenantCleanup.service';
import { TenantCleanupService } from './tenantCleanup.service';
export * from './userAliases.service';
import { UserAliasesService } from './userAliases.service';
export * from './userApplication.service';
import { UserApplicationService } from './userApplication.service';
export * from './userCustomAttribute.service';
import { UserCustomAttributeService } from './userCustomAttribute.service';
export * from './userDirectory.service';
import { UserDirectoryService } from './userDirectory.service';
export * from './userGroup.service';
import { UserGroupService } from './userGroup.service';
export * from './users.service';
import { UsersService } from './users.service';
export * from './version.service';
import { VersionService } from './version.service';
export const APIS = [MyAppGroupService, MyAppGroupApplicationService, PasswordService, SyncUserService, TenantCleanupService, UserAliasesService, UserApplicationService, UserCustomAttributeService, UserDirectoryService, UserGroupService, UsersService, VersionService];
