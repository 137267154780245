/**
 * Identity Server Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CredentialResetLimitPeriodType = 'Days' | 'Weeks' | 'Months' | 'Years';

export const CredentialResetLimitPeriodType = {
    Days: 'Days' as CredentialResetLimitPeriodType,
    Weeks: 'Weeks' as CredentialResetLimitPeriodType,
    Months: 'Months' as CredentialResetLimitPeriodType,
    Years: 'Years' as CredentialResetLimitPeriodType
};

