/**
 * Identity Server Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CredentialResetMethodAppliesTo = 'AllUsers' | 'SpecificGroups';

export const CredentialResetMethodAppliesTo = {
    AllUsers: 'AllUsers' as CredentialResetMethodAppliesTo,
    SpecificGroups: 'SpecificGroups' as CredentialResetMethodAppliesTo
};

