import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthCallbackComponent } from './authentication/auth-callback/auth-callback.component';
import { AuthErrorComponent } from './authentication/auth-error/auth-error.component';
import { ChangePasswordComponent } from './authentication/change-password/change-password.component';
import { EnforceEnrollmentComponent } from './authentication/enforce-enrollment/enforce-enrollment.component';
import { EnforceFidoEnrollmentComponent } from './authentication/enforce-fido-enrollment/enforce-fido-enrollment.component';
import { EnforcePushEnrollmentComponent } from './authentication/enforce-push-enrollment/enforce-push-enrollment.component';
import { LogoutComponent } from './authentication/logout/logout.component';
import { RegisterWelcomeComponent } from './authentication/register-welcome/register-welcome.component';
import { RegistrationComponent } from './authentication/registration/registration.component';
import { SecurityQuestionsContainerComponent } from './authentication/security-questions/security-questions-container/security-questions-container.component';
import { SigninRedirectComponent } from './authentication/signin-redirect/signin-redirect.component';
import { SigninComponent } from './authentication/signin/signin.component';
import { AzureCustomRedirectionComponent } from './cloud/azure/custom.redirection';
import { GoogleAuthIframeComponent } from './cloud/google-auth-iframe/google-auth-iframe.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ErrorComponent } from './error/error.component';
import { MyApplicationsComponent } from './mypplications/mypplications.component';
import { UpdateExpiredComponent } from './organization/licence/update-expired/update-expired.component';
import { ProfileComponent } from './profile/profile.component';
import { ActivateAdmin } from './services/common/activate-admin.service';
import { ActivateAnonymous } from './services/common/activate-anonymous.service';
import { ActivateUsers } from './services/common/activate-users.service';
import { StyledMessageComponent } from './shared/styled-message/styled-message.component';
import { SystemStatusComponent } from './system-status/system-status.component';
import { WhatsNewComponent } from './whats-new/whats-new.component';
import { ActivateMultiTenant } from './services/common/activate-multitenant.service';

const routes: Routes = [
  { path: '', component: SigninComponent, canActivate: [ActivateAnonymous]  },
  { path: 'auth-callback', component: AuthCallbackComponent, canActivate: [ActivateAnonymous]  },
  //{ path: 'silent-refresh', component: SilentRefreshComponent, canActivate: [ActivateAnonymous]  },
  { path: 'login', component: SigninComponent, canActivate: [ActivateAnonymous]  },
  { path: 'signin', component: SigninRedirectComponent, canActivate: [ActivateAnonymous]  },
  { path: 'change-password', component: SigninRedirectComponent, canActivate: [ActivateAnonymous]  },
  { path: 'enforce-enrollment', component: EnforceEnrollmentComponent, canActivate: [ActivateAnonymous]  },
  { path: 'verify-user/:isAskAnswer/:isChangePass', component: SecurityQuestionsContainerComponent, canActivate: [ActivateAnonymous]  },
  { path: 'enforce-push/:type/:isSecondFactor', component: EnforcePushEnrollmentComponent, canActivate: [ActivateAnonymous] },
  { path: 'enforce-fido/:sequence/:isEnforcedSecondFactor', component: EnforceFidoEnrollmentComponent, canActivate: [ActivateAnonymous] },
  { path: 'auth/register', component: RegistrationComponent, canActivate: [ActivateAnonymous, ActivateMultiTenant]  },
  { path: 'auth/register-welcome', component: RegisterWelcomeComponent, canActivate: [ActivateAnonymous, ActivateMultiTenant]  },
  { path: 'auth/change-password', component: ChangePasswordComponent, canActivate: [ActivateAnonymous]  },
  { path: 'auth/change-password/:isSecondFactor', component: ChangePasswordComponent, canActivate: [ActivateAnonymous]  },
  { path: 'auth/change-password/:sequence/:isSecondFactor', component: ChangePasswordComponent, canActivate: [ActivateAnonymous]  },
  { path: 'auth/logout', component: LogoutComponent, canActivate: [ActivateAnonymous]  },
  { path: 'azure-redirect', component: AzureCustomRedirectionComponent, canActivate: [ActivateAnonymous]  },

  { path: 'myapplications', component: MyApplicationsComponent, data: { title: 'Menu.MyApplications' }, canActivate: [ActivateUsers]  },
  { path: 'system-status', component: SystemStatusComponent, data: { title: 'Menu.SystemStatus' }, canActivate: [ActivateUsers]  },
  { path: 'profile', component: ProfileComponent, data: { title: 'Menu.MyProfile' }, canActivate: [ActivateUsers] },
  { path: 'my-profile', loadChildren: () => import('./my-profile/my-profile.module').then((m) => m.MyProfileModule), data: { title: 'Menu.MyProfile'}, canActivate: [ActivateUsers]},
  { path: 'my-profile/:tab', loadChildren: () => import('./my-profile/my-profile.module').then((m) => m.MyProfileModule), data: { title: 'Menu.MyProfile'}, canActivate: [ActivateUsers]},
  { path: 'organization', loadChildren: () => import('./organization/organization.module').then((m) => m.OrganizationModule), data: { title: 'Menu.MSP.Organizations' }, canActivate: [ActivateUsers] },
  { path: 'organization/licence/update/:method/:orgName', component: UpdateExpiredComponent, data: { title: 'Organization.UpdateLicence' }, canActivate: [ActivateAnonymous] },
  { path: 'theme', loadChildren: () => import('./theme/theme.module').then((m) => m.ThemeModule), data: { title: 'Menu.Setup'}, canActivate: [ActivateUsers] },
  { path: 'auth-error', component: AuthErrorComponent, data: { title: 'Authentication.Error' }, canActivate: [ActivateUsers] },
  { path: 'error', component: ErrorComponent, data: { title: 'General.Error' }, canActivate: [ActivateUsers] },

  { path: 'dashboard', component: DashboardComponent, data: { title: 'Menu.Dashboard' }, canActivate: [ActivateAdmin] },
  { path: 'setup', loadChildren: () => import('./setup/setup.module').then((m) => m.SetupModule), data: { title: 'Menu.Setup' }, canActivate: [ActivateAdmin]  },
  { path: 'integration', loadChildren: () => import('./integration/integration.module').then((m) => m.IntegrationModule), data: { title: 'Menu.Integration' }, canActivate: [ActivateAdmin]  },
  { path: 'license', loadChildren: () => import('./setup/setup.module').then((m) => m.SetupModule), data: { title: 'Menu.License' }, canActivate: [ActivateAdmin]  },
  { path: 'application', loadChildren: () => import('./application/application.module').then((m) => m.ApplicationModule), data: { title: 'Menu.Management' } , canActivate: [ActivateAdmin]},
  { path: 'reports', loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule), data: { title: 'Menu.Reports' }, canActivate: [ActivateAdmin] },
  { path: 'conditional-access', loadChildren: () => import('./conditional-access/conditional-access.module').then((m) => m.ConditionalAccessModule), data: { title: 'Menu.Management' }, canActivate: [ActivateAdmin] },
  { path: 'credential-reset', loadChildren: () => import('./credential-reset/credential-reset.module').then((m) => m.CredentialResetModule), data: { title: 'Menu.CredentialReset' }, canActivate: [ActivateAdmin] },
  { path: 'cloud', loadChildren: () => import('./cloud/directory.module').then(m => m.CloudModule), data: { title: 'Menu.Integration' }, canActivate: [ActivateAdmin] },
  { path: 'premises', loadChildren: () => import('./premises/premises.module').then(m => m.PremisesModule), data: { title: 'Menu.Integration' }, canActivate: [ActivateAdmin] },
  { path: 'cloud/azure/custom-redirection', component: AzureCustomRedirectionComponent, canActivate: [ActivateAnonymous] },
  { path: 'cloud/google/auth-iframe', component: GoogleAuthIframeComponent, canActivate: [ActivateAnonymous] },

  { path: 'monitoring', loadChildren: () => import('./monitoring/monitoring.module').then((m) => m.MonitoringModule),  data: { title: 'Menu.Monitoring' }, canActivate: [ActivateAdmin] },
  { path: 'download', loadChildren: () => import('./download/download.module').then(m => m.DownloadModule), data: { title: 'Menu.Downloads'}, canActivate: [ActivateAdmin]},
  { path: 'users', loadChildren: () => import('./user/user.module').then((m) => m.UserModule), data: { title: 'Menu.Management'}, canActivate: [ActivateAdmin] },
  { path: 'activity', loadChildren: () => import('./user/user.module').then((m) => m.UserModule), data: { title: 'Menu.Management'}, canActivate: [ActivateUsers] },
  { path: 'groups', loadChildren: () => import('./group/group.module').then((m) => m.GroupModule), data: { title: 'Menu.Management' }, canActivate: [ActivateAdmin] },
  { path: 'tutorials', loadChildren: () => import('./tutorials/tutorials.module').then(m => m.TutorialsModule), data: { title: 'Menu.Tutorials' }, canActivate: [ActivateAdmin] },
  { path: 'whats-new', component: WhatsNewComponent, data: { title: 'Whats new' }, canActivate: [ActivateUsers]  },

  { path: 'styled-message/:message', component: StyledMessageComponent },

  //{ path: '**', component: SigninComponent, canActivate: [ActivateAnonymous] },

  // to delete them before release - just enable for testing
  //{ path: 'form', loadChildren: () => import('./form/form.module').then((m) => m.FormModule) },
  //{ path: 'charts', loadChildren: () => import('./charts/charts.module').then((m) => m.ChartsDemoModule) },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
