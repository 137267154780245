/**
 * Identity Server Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CredentialResetMethodAppliesTo } from './credentialResetMethodAppliesTo';
import { CredentialResetMethodSendVia } from './credentialResetMethodSendVia';


export interface CredentialResetMethodViewModel { 
    id?: string;
    name?: string | null;
    isEnabled?: boolean;
    enforce2ndFactor?: boolean;
    appliesTo?: CredentialResetMethodAppliesTo;
    groupIds?: Array<number> | null;
    sendVia?: CredentialResetMethodSendVia;
    extraSettings?: { [key: string]: any; } | null;
}

