import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ConstantService, ThemeEnum } from '../../services/common/constant.service';
import { AccountService } from '../../services/identity';
import { CountryService } from '../../services/master';
import { OrganizationAndUserModel, OrganizationModel, OrganizationService, RegistrationResult, UserModel, UserOrgModel } from '../../services/organization';
import { AuthService } from '../auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input'
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from '../../services/user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PolicyModalComponent } from './policy-modal/policy-modal.component';
import { PhoneValidationModal } from './phone-validation-modal/phone-validation-modal.component';
import { EmailCompromisedModal } from './email-compromised-modal/email-compromised-modal';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit, OnDestroy {

  isMnemonicErrorShow: boolean;
  workCountryList: string[];
  isMnemonicShow: boolean;
  orgDomain: string;
  mnemonic: string;
  mnemonicSubCode: string;
  oldUsername: string;
  declarativeFormCaptchaValue: any;
  isSubmit: boolean;
  isPublicDomain: boolean;
  private subscriptions: Subscription = new Subscription;
  isUsernameText: boolean;
  organizationAndUserModel: OrganizationAndUserModel = {} as OrganizationAndUserModel;
  CountryISO = CountryISO;
  SearchCountryField = SearchCountryField;
  PhoneNumberFormat = PhoneNumberFormat;
  configSettings;
  selOrgId: number;

  userModel: UserModel = {
    userID: 0,
    username: null,
    organizationID: null,
    //isLocked: false,
    password: null,
    isActive: true,
    isMustChangePassword: true,
    countryID: null,
    //workCountry: null,
    email: null,
  };

  userOrgModel: UserOrgModel = {
    userModel: this.userModel,
    mnemonic: null,
    //homePageUrl: null,
  };

  intlPhone = {
    countryCode: "",
    dialCode: "",
    e164Number: "",
    internationalNumber: "",
    nationalNumber: "",
    number: "",
  };
  signupBackground: string;
  logoImage: string;

  regForm: FormGroup;
  blackListed = false;

  constructor(
    private authService: AuthService,
    public constantService: ConstantService,
    private identityService: AccountService,
    private countryService: CountryService,
    private organizationService: OrganizationService,
    private router: Router,
    private toastrService: ToastrService,
    private userService: UsersService,
    private ngbModal: NgbModal,
  ) {
    this.isMnemonicErrorShow = false;
    this.orgDomain = environment.publicUrl;
    if (!this.orgDomain.startsWith(".")) {
      this.orgDomain = "." + this.orgDomain;
    }
    this.selOrgId = 0;
  }


  ngOnInit(): void {
    this.regForm = new FormGroup({
      firstName: new FormControl("", [Validators.required, Validators.pattern("[^<>!=`'*]*$")]),
      lastName: new FormControl("", [Validators.required, Validators.pattern("[^<>!=`'*]*$")]),
      username: new FormControl("", [
        Validators.required,
        Validators.pattern(this.constantService.regexUserName),
        Validators.maxLength(19)
      ]),
      email: new FormControl("", [
        Validators.required,
        Validators.pattern(this.constantService.regexUserEmail)
      ]),
      mnemonic: new FormControl("", [Validators.pattern(this.constantService.regexAlphaNumWithOutSpaceDot)]),
      phone: new FormControl("", [Validators.required]),
      country: new FormControl("", [Validators.required])
    })

    this.logoImage = this.constantService.getImageFromServer(ThemeEnum.mainLogo) ?? "assets/images/logo.png";
    this.signupBackground = this.constantService.getImageFromServer(ThemeEnum.signupBackground) ?? "assets/images/register-background.png";

    this.getAllCountries();
    this.getUsernameMaskInfo();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async onSubmit() {

    if (this.regForm.invalid) {
      this.regForm.markAllAsTouched();
      return;
    }

    if(!await this.checkCompromised()) return;
    if(!await this.phoneValidation()) return;

    if (
      this.organizationAndUserModel &&
      this.userOrgModel &&
      !this.isMnemonicErrorShow &&
      !this.isPublicDomain
    ) {
      const mnemonic = this.mnemonic + (this.mnemonicSubCode == null ? "" : this.mnemonicSubCode);
      this.organizationAndUserModel.mnemonic = mnemonic.toLocaleLowerCase();

      this.organizationAndUserModel.contactName = this.userModel.firstName;
      this.organizationAndUserModel.contactEmail = this.userModel.email;
      const publicUrl =
        environment.production ?
          environment.identityApi.replace("{org_code}", mnemonic) : `https://${mnemonic}${this.orgDomain}`
      this.organizationAndUserModel.publicUrl = publicUrl;
      this.organizationAndUserModel.organizationDomain = environment.production ? window.location.hostname : `https://${environment.organizationCode}${this.orgDomain}`;
      this.organizationAndUserModel.userOrgModel = this.userOrgModel;
      this.organizationAndUserModel.userOrgModel.userModel.cell =
        this.intlPhone.e164Number;
      this.organizationAndUserModel.userOrgModel.userModel.organizationID = 0;
      const uri = `${this.organizationAndUserModel.mnemonic}${this.orgDomain}`;

      this.organizationAndUserModel.isActive = true;

      this.isSubmit = false;
      this.subscriptions.add(
        this.organizationService
          .apiOrganizationCreateOrganizationAndUserPost(this.organizationAndUserModel)
          .subscribe({
            complete: () => {
              this.router
                .navigate(["auth", "register-welcome"], {
                  queryParams: { url: uri },
                })
                .then(() => {
                  this.authService.reloadPage();
                });
            },
            error: () => {
              this.isSubmit = true;
              this.toastrService.error(
                "Organization not register",
                "Error", this.constantService.ToastError
              );
            }
          })
      );
    }
  }

  async checkCompromised(): Promise<boolean> {
    try {
      const data = await firstValueFrom(
        this.userService.apiUsersCheckNewTenantCompromisedAccountGet(this.userModel.email)
      );
      if (data != null) {
        const modalRef = this.ngbModal.open(EmailCompromisedModal, { size: 'lg' });
        return await modalRef.result;
      } else {
        return true;
      }
    } catch (error) {
      console.error("An error occurred:", error);
      return false;
    }
  }

  async phoneValidation(): Promise<boolean> {
    const modalRef = this.ngbModal.open(PhoneValidationModal, { size: 'md' });
    modalRef.componentInstance.phone = this.intlPhone.e164Number;

    try {
        const isAdminValidated = await modalRef.result;
        return isAdminValidated;
    } catch (error) {
        console.error('Error in admin validation modal:', error);
        return false;
    }
  }

  createUser(orgData) {
    let country;
    if (this.userOrgModel) {
      this.userOrgModel.userModel.organizationID = orgData.organizationId;
      this.userOrgModel.mnemonic = this.organizationAndUserModel.mnemonic;
      const uri = `${this.organizationAndUserModel.mnemonic}${this.orgDomain}`;
      this.userOrgModel.userModel.cell = this.intlPhone.e164Number;
      country = this.workCountryList.find(
        (x) => x["countryId"] == this.userModel.countryID
      );
      this.userModel.countryID = country?.countryId ? +country.countryId : null;
      this.subscriptions.add(
        this.userService.apiUsersAddOrgUserPost(this.organizationAndUserModel).subscribe({
          complete: () => {
            this.router
              .navigate(["auth", "register-welcome"], {
                queryParams: { url: uri },
              })
              .then(() => {
                this.authService.reloadPage();
              });
          },
          error: () => {
            this.toastrService.error(
              "Organization not register",
              "Error", this.constantService.ToastError
            );
          }
        }
        )
      );
    }
  }

  public getKey() {
    return environment.reCaptchaSiteKey;
  }

  companyCode(type) {
    let mnemonic;
    if (type === "code") {
      mnemonic = this.mnemonic;
    } else {
      const email = this.userModel.email.toLowerCase();
      let domain = email.substring(email.lastIndexOf("@") + 1);
      mnemonic = domain;
      mnemonic = mnemonic.substr(0, mnemonic.indexOf("."));
      this.isPublicDomain = this.checkPublicDomain(domain);
      if (this.isPublicDomain) {
        return false;
      }
    }
    mnemonic = mnemonic.replace(".", "-");
    this.mnemonic = mnemonic;
    if (this.mnemonicSubCode != null) {
      mnemonic = mnemonic + this.mnemonicSubCode;
    }
    this.checkOrganization(mnemonic);
  }
  
  private checkOrganization(code) {
    this.blackListed = false;
    this.subscriptions.add(
      this.organizationService.apiOrganizationOrganizationMnemonicGet(code)
        .subscribe((data: RegistrationResult) => {
          switch (data) {
            case RegistrationResult.Allowed:
              this.organizationAndUserModel.organizationName = code;
              this.isMnemonicErrorShow = false;
              if (this.mnemonicSubCode == null) {
                this.mnemonic = code;
              }
              if (
                this.mnemonicSubCode == null ||
                this.mnemonicSubCode === ""
              ) {
                this.isMnemonicShow = false;
              }

              if (this.getKey() === '') {
                this.isSubmit = true;
              }
              break;
          
            case RegistrationResult.TenantExists:
              //this.regForm.controls.mnemonic.setErrors({ 'incorrect': true });
              this.isMnemonicErrorShow = true;
              this.isMnemonicShow = true;
              break;

            case RegistrationResult.BlackListed:
              this.regForm.controls.mnemonic.setErrors({ 'incorrect': true });
              this.isMnemonicErrorShow = true;
              this.isMnemonicShow = false;
              this.blackListed = true;
              break;
          }

        })
    );
  }

  private checkPublicDomain(domain) {
    //const index = this.publicDomainService.publicDomains.findIndex(
    //  (x) => x === domain
    //);
    //if (index > -1) {
    //  return true;
    //} else {
    //  return false;
    //}
    return false;
  }

  private getUsernameMaskInfo() {
    this.subscriptions.add(
      this.identityService.apiAccountGetOrganizationSettingsGet().subscribe((data) => {
        this.configSettings = data;
        if (this.configSettings[0].value === "true")
          this.isUsernameText = false;
        else this.isUsernameText = true;
      })
    );
  }

  resolved(captchaResponse: string) {
    this.organizationAndUserModel.recaptchaClientResponse = captchaResponse;
    if (captchaResponse == null) {
      this.isSubmit = false;
    } else {
      this.isSubmit = true;
    }
  }

  private getAllCountries() {
    this.subscriptions.add(
      this.countryService.apiCountryGetAllCountriesGet().subscribe((data) => {
        this.workCountryList = data;
      })
    );
  }


  login() {
    this.authService.login();
  }

  policy() {
    this.ngbModal.open(PolicyModalComponent, {
      size: "lg",
      windowClass: "fadeInUp animated huge",
      backdrop: "static",
      keyboard: false,
    });
  }

}
