import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { HasPermissionDirective } from '../services/common/has-permission.directive';
import { HasPermissionForTargetDirective } from '../services/common/has-permission-for-target.directive';
import { ConfirmDialogModule } from './confirm-dialog/confirm-dialog.module';
import { StyledMessageComponent } from './styled-message/styled-message.component';
import { BlockCopyPasteDirective } from '../services/common/block-paste.directive';
import { TreeviewModule } from 'ngx-treeview';
import { DatatableComponent } from './datatable/datatable.component';
import { DataTablesModule } from 'angular-datatables';
import { EnrollmentContainerComponent } from './enrollment/enrollment-container/enrollment-container.component';
import { SeSharedModule } from '@securenvoy/se-shared';
import { environment } from '../../environments/environment';

@NgModule({
  declarations: [
    HasPermissionDirective,
    HasPermissionForTargetDirective,
    BlockCopyPasteDirective,
    StyledMessageComponent,
    DatatableComponent,
    EnrollmentContainerComponent
  ],
  imports: [
    TreeviewModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    CommonModule,
    NgSelectModule,
    NgbModule,
    ConfirmDialogModule,
    DataTablesModule,
    SeSharedModule.forRoot({production: environment.production, pushCheckTime: environment.pushCheckTime})
  ],
  exports: [
    TreeviewModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    CommonModule,
    NgSelectModule,
    NgbModule,
    HasPermissionDirective,
    HasPermissionForTargetDirective,
    BlockCopyPasteDirective,
    ConfirmDialogModule,
    DatatableComponent,
    EnrollmentContainerComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {
}
