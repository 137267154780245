/**
 * Identity Server Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CredentialResetLimitPeriodType } from './credentialResetLimitPeriodType';
import { CredentialResetMethodViewModel } from './credentialResetMethodViewModel';


export interface CredentialResetSettingsViewModel { 
    id?: string;
    orgId?: number;
    isEnabled?: boolean;
    allowLockedAccounts?: boolean;
    methods?: Array<CredentialResetMethodViewModel> | null;
    isResetLimitEnabled?: boolean;
    numberOfResets?: number;
    resetLimitPeriod?: number;
    resetLimitPeriodType?: CredentialResetLimitPeriodType;
    alertGroupIds?: Array<number> | null;
}

